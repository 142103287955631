<template>
    <div class="container">
        <div class="text-center mt-3 mb-2">
            <img src="https://www.loewe-zaun.de/wp-content/uploads/2021/11/vielenk_dank.png">
            <h1 class="text-center">Vielen Dank für Ihre Anfrage!</h1>
        </div>
        <div class="row">
            <div class="col-md-8">
                <p>Wir setzen uns schnellstmöglich mit Ihnen in Verbindung und beraten Sie gern.</p>
                <p>Nach dem Anfrage wird sich ein Kundenberater bei Ihnen melden um Einzelheiten und Details zu besprechen und Ihnen ein Angebot für die bestellte Zaunanlage zusenden.</p>
                <p>Alle Details werden später mit unseren Kundenberater besprochen und angepasst.</p>
                <p>Nutzen Sie in der Zwischenzeit doch die Gelegenheit das Unternehmen besser kennenzulernen.</p>
                <p class="mt-3">Kennen Sie schon unsere 3 Standorte in Berlin und Brandenburg ?</p>
                <h4>Wir liefern und montieren deutschlandweit!</h4>
            </div>
            <div class="col-md-4">
                <img src="https://www.loewe-zaun.de/wp-content/uploads/2024/02/beratung-von-experten.jpg" class="img-fluid">
            </div>
        </div>
    </div>
</template>
<script>
import { useMeta } from 'vue-meta'
export default {
  setup () {
    useMeta({
      title: 'Anfrage erhalten - Loewe-Zaun.de - Konfigurator'
    })
  }
}
</script>
